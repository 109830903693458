exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-authors-js": () => import("./../../../src/templates/authors.js" /* webpackChunkName: "component---src-templates-authors-js" */),
  "component---src-templates-post-js-content-file-path-blogs-2012-09-03-welcome-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2012-09-03-welcome.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2012-09-03-welcome-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2012-09-04-receive-sms-online-free-of-charge-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2012-09-04-receive-sms-online-free-of-charge_.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2012-09-04-receive-sms-online-free-of-charge-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2012-09-18-xmartlabs-in-startup-weekend-montevideo-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2012-09-18-xmartlabs-in-startup-weekend-montevideo.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2012-09-18-xmartlabs-in-startup-weekend-montevideo-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2012-12-05-xmartlabs-moves-to-latu-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2012-12-05-xmartlabs-moves-to-latu.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2012-12-05-xmartlabs-moves-to-latu-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2013-03-21-xmartlabs-at-rubyconf-uruguay-2013-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2013-03-21-xmartlabs-at-rubyconf-uruguay-2013.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2013-03-21-xmartlabs-at-rubyconf-uruguay-2013-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2013-05-03-summary-rubyconf-uy-2013-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2013-05-03-summary-rubyconf-uy-2013.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2013-05-03-summary-rubyconf-uy-2013-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2013-06-09-wwdc-2013-apple-worldwide-developers-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2013-06-09-wwdc-2013-apple-worldwide-developers.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2013-06-09-wwdc-2013-apple-worldwide-developers-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2013-08-13-speakers-at-jiap-2013-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2013-08-13-speakers-at-jiap-2013.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2013-08-13-speakers-at-jiap-2013-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2014-11-25-now-with-more-uxd-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2014-11-25-now-with-more-uxd.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2014-11-25-now-with-more-uxd-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2014-12-02-linkedin-for-work-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2014-12-02-linkedin-for-work.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2014-12-02-linkedin-for-work-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2015-01-12-linkedin-for-work-pt-2-finding-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2015-01-12-linkedin-for-work-pt-2-finding.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2015-01-12-linkedin-for-work-pt-2-finding-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2015-04-16-oss-culture-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2015-04-16-OSS-Culture.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2015-04-16-oss-culture-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2015-04-28-xl-form-using-nspredicates-to-change-form-structure-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2015-04-28-XLForm-using-nspredicates-to-change-form-structure.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2015-04-28-xl-form-using-nspredicates-to-change-form-structure-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2015-07-09-android-logging-with-crashlytics-and-timber-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2015-07-09-Android-logging-with-Crashlytics-and-Timber.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2015-07-09-android-logging-with-crashlytics-and-timber-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2015-09-29-introducing-eureka-i-os-form-library-written-in-pure-swift-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2015-09-29-Introducing-Eureka-iOS-form-library-written-in-pure-Swift.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2015-09-29-introducing-eureka-i-os-form-library-written-in-pure-swift-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2015-10-16-wwdc-2015-by-helen-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2015-10-16-WWDC-2015-by-Helen.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2015-10-16-wwdc-2015-by-helen-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2016-03-07-ci-with-xcode-server-and-fastlane-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2016-03-07-ci-with-xcode-server-and-fastlane.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2016-03-07-ci-with-xcode-server-and-fastlane-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2016-03-28-debugging-an-annotator-processor-in-your-project-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2016-03-28-Debugging-an-Annotator-Processor-in-your-project.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2016-03-28-debugging-an-annotator-processor-in-your-project-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2016-06-24-xmartlabs-chosen-to-speak-at-swift-with-the-best-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2016-06-24-xmartlabs-chosen-to-speak-at-Swift-With-the-Best.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2016-06-24-xmartlabs-chosen-to-speak-at-swift-with-the-best-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2016-08-30-making-a-js-plug-in-a-full-stack-approach-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2016-08-30-making-a-js-plug-in-a-full-stack-approach.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2016-08-30-making-a-js-plug-in-a-full-stack-approach-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2016-09-06-eureka-custom-row-tutorial-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2016-09-06-eureka-custom-row-tutorial.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2016-09-06-eureka-custom-row-tutorial-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2016-09-23-eureka-custom-row-tutorial-2-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2016-09-23-eureka-custom-row-tutorial-2.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2016-09-23-eureka-custom-row-tutorial-2-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2016-10-17-introducing-ecno-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2016-10-17-introducing-ecno.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2016-10-17-introducing-ecno-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2016-10-21-the-first-eureka-webinar-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2016-10-21-The-First-Eureka-Webinar.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2016-10-21-the-first-eureka-webinar-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2017-05-01-introducing-to-ahoy-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2017-05-01-Introducing-to-Ahoy.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2017-05-01-introducing-to-ahoy-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2017-08-10-bender-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2017-08-10-bender.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2017-08-10-bender-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2017-09-25-i-os-11-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2017-09-25-iOS11.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2017-09-25-i-os-11-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2017-10-02-release-snapshot-versions-in-jenkins-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2017-10-02-Release-Snapshot-Versions-In-Jenkins.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2017-10-02-release-snapshot-versions-in-jenkins-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2017-10-13-design-sprint-1-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2017-10-13-DesignSprint1.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2017-10-13-design-sprint-1-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2017-10-13-design-sprint-2-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2017-10-13-DesignSprint2.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2017-10-13-design-sprint-2-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2017-10-19-disrupt-2017-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2017-10-19-Disrupt2017.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2017-10-19-disrupt-2017-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2017-11-01-introducing-to-xniffer-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2017-11-01-Introducing-to-Xniffer.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2017-11-01-introducing-to-xniffer-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2017-11-09-eureka-4-0-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2017-11-09-Eureka-4.0.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2017-11-09-eureka-4-0-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2017-11-3-react-native-line-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2017-11-3-React-native-line.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2017-11-3-react-native-line-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2017-12-04-xmartlabs-sam-mc-afee-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2017-12-04-Xmartlabs-Sam-McAfee.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2017-12-04-xmartlabs-sam-mc-afee-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2018-06-21-ml-kit-core-ml-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2018-06-21-ML-Kit-CoreML.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2018-06-21-ml-kit-core-ml-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2018-07-16-introducing-fountain-part-one-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2018-07-16-Introducing-Fountain-Part-One.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2018-07-16-introducing-fountain-part-one-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2018-08-20-introducing-fountain-part-two-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2018-08-20-Introducing-Fountain-Part-Two.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2018-08-20-introducing-fountain-part-two-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2018-08-24-how-to-revolutionize-your-company-with-augmented-reality-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2018-08-24-How-to-revolutionize-your-company-with-augmented-reality.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2018-08-24-how-to-revolutionize-your-company-with-augmented-reality-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2018-10-08-how-ar-can-create-an-roi-for-the-enterprise-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2018-10-08-How-AR-can-create-an-ROI-for-the-enterprise.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2018-10-08-how-ar-can-create-an-roi-for-the-enterprise-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2018-12-03-how-nfc-can-help-improve-your-business-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2018-12-03-how-NFC-can-help-improve-your-business.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2018-12-03-how-nfc-can-help-improve-your-business-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2018-12-04-xmartlabs-distinguished-amongst-the-clutch-1000-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2018-12-04-xmartlabs-distinguished-amongst-the-clutch-1000.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2018-12-04-xmartlabs-distinguished-amongst-the-clutch-1000-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2019-04-18-introducing-android-snapshot-publisher-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2019-04-18-Introducing-Android-Snapshot-Publisher.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2019-04-18-introducing-android-snapshot-publisher-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2019-05-17-url-splitting-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2019-05-17-url-splitting.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2019-05-17-url-splitting-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2019-06-3-swiftui-flutter-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2019-06-3-swiftui-flutter.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2019-06-3-swiftui-flutter-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2019-07-01-redshift-snowflake-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2019-07-01-redshift-snowflake.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2019-07-01-redshift-snowflake-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2019-08-20-first-xmartlabs-hackathon-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2019-08-20-first-xmartlabs-hackathon.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2019-08-20-first-xmartlabs-hackathon-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2019-11-12-tflite-to-coreml-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2019-11-12-tflite-to-coreml.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2019-11-12-tflite-to-coreml-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-03-30-ios-continuous-integration-delivery-using-fastlane-and-bitrise-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-03-30-ios-continuous-integration-delivery-using-fastlane-and-bitrise.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-03-30-ios-continuous-integration-delivery-using-fastlane-and-bitrise-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-04-22-powerful-animations-in-rn-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-04-22-powerful-animations-in-RN.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-04-22-powerful-animations-in-rn-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-05-04-why-sign-in-with-apple-and-integration-guide-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-05-04-why-sign-in-with-apple-and-integration-guide.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-05-04-why-sign-in-with-apple-and-integration-guide-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-05-07-speakers-at-appbuilders-with-covid-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-05-07-speakers-at-appbuilders-with-covid.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-05-07-speakers-at-appbuilders-with-covid-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-05-11-should-we-automate-all-testing-effort-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-05-11-should-we-automate-all-testing-effort.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-05-11-should-we-automate-all-testing-effort-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-05-19-appbuilders-conference-recap-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-05-19-appbuilders-conference-recap.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-05-19-appbuilders-conference-recap-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-05-25-android-nav-component-part-1-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-05-25-android-nav-component-part-1.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-05-25-android-nav-component-part-1-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-06-01-how-to-speed-up-inference-in-your-deep-learning-model-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-06-01-how-to-speed-up-inference-in-your-deep-learning-model.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-06-01-how-to-speed-up-inference-in-your-deep-learning-model-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-06-05-how-to-deal-with-coronavirus-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-06-05-how-to-deal-with-coronavirus.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-06-05-how-to-deal-with-coronavirus-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-06-19-getting-started-with-jamstack-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-06-19-getting-started-with-jamstack.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-06-19-getting-started-with-jamstack-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-07-09-frontend-architecture-and-best-practices-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-07-09-frontend-architecture-and-best-practices.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-07-09-frontend-architecture-and-best-practices-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-08-04-latest-updates-on-apple-machine-learning-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-08-04-latest-updates-on-apple-machine-learning.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-08-04-latest-updates-on-apple-machine-learning-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-08-17-sign-in-with-apple-with-swiftui-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-08-17-sign-in-with-apple-with-swiftui.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-08-17-sign-in-with-apple-with-swiftui-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-10-02-react-native-line-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-10-02-react-native-line.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-10-02-react-native-line-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-12-08-key-metrics-for-growing-digital-startups-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-12-08-key-metrics-for-growing-digital-startups.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-12-08-key-metrics-for-growing-digital-startups-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2020-12-31-recap-2020-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2020-12-31-recap2020.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2020-12-31-recap-2020-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2021-05-11-compose-themes-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2021-05-11-compose-themes.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2021-05-11-compose-themes-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2021-07-30-gong-introduction-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2021-07-30-gong-introduction.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2021-07-30-gong-introduction-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2021-07-30-introducing-pagertabstrip-swiftui-pager-view-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2021-07-30-Introducing-pagertabstrip-swiftui-pager-view.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2021-07-30-introducing-pagertabstrip-swiftui-pager-view-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2021-08-11-testing-uy-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2021-08-11-testing-uy.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2021-08-11-testing-uy-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2021-08-17-dogface-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2021-08-17-dogface.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2021-08-17-dogface-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2021-08-30-bender-is-the-best-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2021-08-30-bender-is-the-best.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2021-08-30-bender-is-the-best-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2021-09-30-body-detection-with-computer-vision-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2021-09-30-body-detection-with-computer-vision.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2021-09-30-body-detection-with-computer-vision-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2021-10-07-techcrunch-disrupt-2021-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2021-10-07-techcrunch-disrupt2021.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2021-10-07-techcrunch-disrupt-2021-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2021-10-15-design-your-strategy-like-google-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2021-10-15-design-your-strategy-like-google.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2021-10-15-design-your-strategy-like-google-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2021-10-15-techcrunch-disrupt-2021-crypto-landscape-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2021-10-15-techcrunch-disrupt2021-crypto-landscape.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2021-10-15-techcrunch-disrupt-2021-crypto-landscape-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2021-11-30-nfl-kaggle-competition-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2021-11-30-nfl-kaggle-competition.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2021-11-30-nfl-kaggle-competition-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-01-24-object-detection-use-case-occupancy-analytics-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-01-24-object-detection-use-case-occupancy-analytics.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-01-24-object-detection-use-case-occupancy-analytics-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-01-28-airflow-taskflow-a-must-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-01-28-airflow-taskflow-a-must.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-01-28-airflow-taskflow-a-must-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-02-24-intro-to-recommendations-engine-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-02-24-intro-to-recommendations-engine.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-02-24-intro-to-recommendations-engine-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-03-03-10-years-in-tech-industry-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-03-03-10-years-in-tech-industry.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-03-03-10-years-in-tech-industry-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-03-21-superresolution-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-03-21-superresolution.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-03-21-superresolution-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-03-24-10-years-xl-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-03-24-10-years-xl.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-03-24-10-years-xl-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-03-28-farming-with-machine-learning-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-03-28-farming-with-machine-learning.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-03-28-farming-with-machine-learning-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-05-06-user-research-process-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-05-06-user-research-process.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-05-06-user-research-process-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-05-11-the-xl-way-closing-the-gender-gap-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-05-11-the-xl-way-closing-the-gender-gap.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-05-11-the-xl-way-closing-the-gender-gap-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-06-03-choosing-the-right-blockchain-for-your-next-nft-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-06-03-Choosing-the-right-Blockchain-for-your-next-NFT.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-06-03-choosing-the-right-blockchain-for-your-next-nft-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-06-06-only-one-earth-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-06-06-only-one-earth.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-06-06-only-one-earth-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-06-23-appjs-conf-takeaways-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-06-23-appjs-conf-takeaways.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-06-23-appjs-conf-takeaways-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-06-27-top-evm-compatible-frameworks-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-06-27-Top EVM-compatible-frameworks.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-06-27-top-evm-compatible-frameworks-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-07-25-challenges-of-building-a-web-with-flutter-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-07-25-challenges-of-building-a-web-with-flutter.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-07-25-challenges-of-building-a-web-with-flutter-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-07-25-going-international-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-07-25-going-international.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-07-25-going-international-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-07-27-basic-infrastructure-in-aws-using-terraform-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-07-27-basic-infrastructure-in-AWS-using-terraform.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-07-27-basic-infrastructure-in-aws-using-terraform-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-09-29-importance-qa-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-09-29-importance-qa.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-09-29-importance-qa-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-10-05-introducing-stock-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-10-05-introducing-stock.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-10-05-introducing-stock-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2022-11-18-posts-data-analytics-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2022-11-18-posts_data_analytics.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2022-11-18-posts-data-analytics-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-02-09-fluttips-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-02-09-Fluttips.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-02-09-fluttips-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-03-15-building-a-zero-shot-sign-pose-embedding-model-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-03-15-building-a-zero-shot-sign-pose-embedding-model.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-03-15-building-a-zero-shot-sign-pose-embedding-model-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-03-15-from-idea-to-mvp-the-journey-of-designing-a-tool-for-practicing-sign-language-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-03-15-from-idea-to-MVP-the-journey-of-designing-a-tool-for-practicing-sign-language.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-03-15-from-idea-to-mvp-the-journey-of-designing-a-tool-for-practicing-sign-language-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-03-24-alpaca-trading-api-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-03-24-alpaca-trading-API.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-03-24-alpaca-trading-api-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-03-24-neobanking-and-synapse-fi-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-03-24-neobanking-and-synapseFi.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-03-24-neobanking-and-synapse-fi-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-03-30-streaming-data-sources-with-kafka-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-03-30-streaming-data-sources-with-kafka.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-03-30-streaming-data-sources-with-kafka-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-04-12-type-script-type-guards-an-introduction-to-writing-infinite-scrolling-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-04-12-typeScript-type-guards-an-introduction-to-writing-infinite-scrolling.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-04-12-type-script-type-guards-an-introduction-to-writing-infinite-scrolling-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-04-19-effortless-scrolling-how-to-implement-infinite-scrolling-in-react-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-04-19-effortless-scrolling-how-to-implement-infinite-scrolling-in-react.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-04-19-effortless-scrolling-how-to-implement-infinite-scrolling-in-react-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-07-05-atomic-design-systems-on-figma-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-07-05-atomic-design-systems-on-figma.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-07-05-atomic-design-systems-on-figma-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-07-12-i-tested-react-native-new-architecture-improvements-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-07-12-i-tested-react-native-new-architecture-improvements.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-07-12-i-tested-react-native-new-architecture-improvements-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-07-21-tf-recommenders-encode-user-watch-history-using-openai-embeddings-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-07-21-tf-recommenders-encode-user-watch-history-using-openai-embeddings.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-07-21-tf-recommenders-encode-user-watch-history-using-openai-embeddings-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-08-03-ai-driven-product-hyper-personalization-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-08-03-ai-driven-product-hyper-personalization.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-08-03-ai-driven-product-hyper-personalization-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-09-24-deploying-a-recommendation-system-using-fastapi-tf-serving-and-feast-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-09-24-deploying-a-recommendation-system-using-fastapi-tf-serving-and-feast.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-09-24-deploying-a-recommendation-system-using-fastapi-tf-serving-and-feast-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-09-24-ml-model-monitoring-boosting-performance-and-reliability-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-09-24-ml-model-monitoring-boosting-performance-and-reliability.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-09-24-ml-model-monitoring-boosting-performance-and-reliability-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-10-04-adapting-teams-to-enterprise-needs-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-10-04-adapting-teams-to-enterprise-needs.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-10-04-adapting-teams-to-enterprise-needs-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-10-05-securing-development-and-ensuring-compliance-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-10-05-securing-development-and-ensuring-compliance.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-10-05-securing-development-and-ensuring-compliance-md" */),
  "component---src-templates-post-js-content-file-path-blogs-2023-10-20-how-to-estimate-an-accurate-budget-for-your-product-development-project-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/2023-10-20-how-to-estimate-an-accurate-budget-for-your-product-development-project.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-2023-10-20-how-to-estimate-an-accurate-budget-for-your-product-development-project-md" */),
  "component---src-templates-post-js-content-file-path-blogs-activestorage-cloudfront-optimizing-media-download-speed-in-rails-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/activestorage-cloudfront-optimizing-media-download-speed-in-rails.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-activestorage-cloudfront-optimizing-media-download-speed-in-rails-md" */),
  "component---src-templates-post-js-content-file-path-blogs-ai-trends-to-watch-in-2024-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/ai-trends-to-watch-in-2024.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-ai-trends-to-watch-in-2024-md" */),
  "component---src-templates-post-js-content-file-path-blogs-bedtime-cybersecurity-stories-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/bedtime-cybersecurity-stories.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-bedtime-cybersecurity-stories-md" */),
  "component---src-templates-post-js-content-file-path-blogs-building-bridges-how-were-crafting-a-woman-safe-workplace-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/building-bridges-how-were-crafting-a-woman-safe-workplace.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-building-bridges-how-were-crafting-a-woman-safe-workplace-md" */),
  "component---src-templates-post-js-content-file-path-blogs-building-high-performing-teams-inside-xmartlabs-recruitment-process-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/building-high-performing-teams-inside-xmartlabs-recruitment-process.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-building-high-performing-teams-inside-xmartlabs-recruitment-process-md" */),
  "component---src-templates-post-js-content-file-path-blogs-closing-the-pop-up-dilemma-a-deep-dive-into-ux-discontent-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/closing-the-pop-up-dilemma-a-deep-dive-into-ux-discontent.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-closing-the-pop-up-dilemma-a-deep-dive-into-ux-discontent-md" */),
  "component---src-templates-post-js-content-file-path-blogs-data-governance-framework-elevating-datas-potential-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/data-governance-framework-elevating-datas-potential.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-data-governance-framework-elevating-datas-potential-md" */),
  "component---src-templates-post-js-content-file-path-blogs-designing-effective-ml-projects-best-practices-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/designing-effective-ml-projects-best-practices.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-designing-effective-ml-projects-best-practices-md" */),
  "component---src-templates-post-js-content-file-path-blogs-devops-101-optimizing-tech-solutions-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/devops-101-optimizing-tech-solutions.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-devops-101-optimizing-tech-solutions-md" */),
  "component---src-templates-post-js-content-file-path-blogs-empowering-value-base-care-with-ai-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/empowering-value-base-care-with-ai.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-empowering-value-base-care-with-ai-md" */),
  "component---src-templates-post-js-content-file-path-blogs-experiment-tracking-done-right-relevancy-and-best-practices-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/experiment-tracking-done-right-relevancy-and-best-practices.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-experiment-tracking-done-right-relevancy-and-best-practices-md" */),
  "component---src-templates-post-js-content-file-path-blogs-exploring-apple-vision-pro-key-takeaways-from-our-devs-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/exploring-apple-vision-pro-key-takeaways-from-our-devs.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-exploring-apple-vision-pro-key-takeaways-from-our-devs-md" */),
  "component---src-templates-post-js-content-file-path-blogs-from-react-native-to-react-js-a-developers-journey-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/from-react-native-to-react-js-a-developers-journey.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-from-react-native-to-react-js-a-developers-journey-md" */),
  "component---src-templates-post-js-content-file-path-blogs-how-okr-implementation-elevated-our-performance-practical-tips-for-your-organization-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/how-okr-implementation-elevated-our-performance-practical-tips-for-your-organization.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-how-okr-implementation-elevated-our-performance-practical-tips-for-your-organization-md" */),
  "component---src-templates-post-js-content-file-path-blogs-insights-from-experts-on-ai-and-data-governance-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/insights-from-experts-on-ai-and-data-governance.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-insights-from-experts-on-ai-and-data-governance-md" */),
  "component---src-templates-post-js-content-file-path-blogs-interruption-testing-what-is-it-and-why-it-matters-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/interruption-testing-what-is-it-and-why-it-matters.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-interruption-testing-what-is-it-and-why-it-matters-md" */),
  "component---src-templates-post-js-content-file-path-blogs-power-dynamics-the-founder-investor-relationship-in-the-startup-industry-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/power-dynamics-the-founder-investor-relationship-in-the-startup-industry.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-power-dynamics-the-founder-investor-relationship-in-the-startup-industry-md" */),
  "component---src-templates-post-js-content-file-path-blogs-section-174-decoded-its-unspoken-role-in-the-us-startup-ecosystem-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/section-174-decoded-its-unspoken-role-in-the-us-startup-ecosystem.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-section-174-decoded-its-unspoken-role-in-the-us-startup-ecosystem-md" */),
  "component---src-templates-post-js-content-file-path-blogs-stakeholder-alignment-in-enterprise-digital-projects-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/stakeholder-alignment-in-enterprise-digital-projects.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-stakeholder-alignment-in-enterprise-digital-projects-md" */),
  "component---src-templates-post-js-content-file-path-blogs-streamlining-information-crafting-an-ai-assistant-at-xmartlabs-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/streamlining-information-crafting-an-ai-assistant-at-xmartlabs.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-streamlining-information-crafting-an-ai-assistant-at-xmartlabs-md" */),
  "component---src-templates-post-js-content-file-path-blogs-successful-ai-integration-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/successful-ai-integration.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-successful-ai-integration-md" */),
  "component---src-templates-post-js-content-file-path-blogs-testinguy-2024-embracing-ai-without-fear-md": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/blogs/testinguy-2024-embracing-ai-without-fear.md" /* webpackChunkName: "component---src-templates-post-js-content-file-path-blogs-testinguy-2024-embracing-ai-without-fear-md" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

